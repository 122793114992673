import { Article } from '@/Article';
import { PrimaryBtn } from '@/Buttons/PrimaryBtn';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { info } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const PatientForm: Page = () => (
    <Fragment>
        <Seo
            title="Online Patient Form | Root Canal Services Near Me"
            description="Complete Nuttall Smiles' online patient form for seamless services from the best dentist in Auburn and surrounding areas. Request an appointment now."
        />
        <CommonHero img>
            <StaticImage
                src="../../../assets/images/for-patients/online-patient-form.jpg"
                alt="depicting a form"
                layout="fullWidth"
                loading="eager"
            />
        </CommonHero>
        <Article>
            <p>
                When you visit the dentist’s office, there is usually some paperwork to fill out.
                For your convenience, we have made our patient forms available online. This saves
                you time at our dental office in Auburn, Washington, and gives our team more time to
                focus on you and your dental needs. If you have questions about our patient forms,
                please call Nuttall Smiles at{' '}
                <CLink to={info.phone.link}>
                    <b>{info.phone.text}</b>
                </CLink>
                . We will be happy to assist you.
            </p>
            <PrimaryBtn
                // as="a"
                // href="/New-Patient-Form-Web.pdf"
                as={CLink}
                to="/New-Patient-Form-Web.pdf"
                data-type="button"
                css={theme => css`
                    background-color: ${theme.colors.purple1};
                    color: ${theme.colors.white};
                    margin: 24px auto 0;
                    display: flex;
                    width: fit-content;

                    ::after {
                        filter: brightness(0) invert(1);
                    }
                `}
            >
                View Patient Form
            </PrimaryBtn>
        </Article>
    </Fragment>
);

export default PatientForm;
